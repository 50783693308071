/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    twitter: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334q.002-.211-.006-.422A6.7 6.7 0 0016 3.542a6.7 6.7 0 01-1.889.518 3.3 3.3 0 001.447-1.817 6.5 6.5 0 01-2.087.793A3.286 3.286 0 007.875 6.03a9.32 9.32 0 01-6.767-3.429 3.29 3.29 0 001.018 4.382A3.3 3.3 0 01.64 6.575v.045a3.29 3.29 0 002.632 3.218 3.2 3.2 0 01-.865.115 3 3 0 01-.614-.057 3.28 3.28 0 003.067 2.277A6.6 6.6 0 01.78 13.58a6 6 0 01-.78-.045A9.34 9.34 0 005.026 15"/>',
    },
});
